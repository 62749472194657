<template>
	<article class="quiz__block textarea">
		<h2 class="quiz__block--title">Question #{{ index }}</h2>
		<p>{{ content }}</p>
		<v-textarea v-model="answer"></v-textarea>
	</article>
</template>

<script>
export default {
	name: "QuizFreeForm",
	props: {
		content: {
			type: String,
			require: true
		},
		index: {
			type: Number,
			require: true
		},
		selectedAnswers: {
			type: Object,
			require: true
		},
		questionId: {
			type: Number,
			require: true
		}
	},
	computed: {
		answer: {
			get() {
				return this.selectedAnswers[this.questionId];
			},
			set(val) {
				this.$emit("addSelectedAnswer", {
					id: this.questionId,
					value: val
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped></style>
